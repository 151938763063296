import { Component, Input } from "@angular/core";

@Component({
  selector: "app-completion-state",
  templateUrl: "./completion-state.component.html",
  styleUrl: "./completion-state.component.css",
})
export class CompletionStateComponent {
  @Input() completionState!: boolean;
  @Input() tooltipTrue!: string;
  @Input() tooltipFalse!: string;
}
