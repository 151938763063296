import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { displayValue, fileType } from "app/Enumerations/file-type.enum";
import { MediaAssociation, MediaAssociationTranslation } from "app/Models/module";

@Component({
  selector: "app-associated-media-card",
  templateUrl: "./associated-media-card.component.html",
  styleUrl: "./associated-media-card.component.css",
})
export class AssociatedMediaCardComponent implements OnInit, OnChanges {
  @Input() formArrayIndex!: number;
  @Input() mediaAssociation!: MediaAssociation;

  @Output() dragStartedEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() showAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteAssociatedMediaEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() renameMediaAssociationEmitter: EventEmitter<MediaAssociationTranslation> = new EventEmitter<MediaAssociationTranslation>();
  @Output() saveMediaAssociationInfosEmitter: EventEmitter<MediaAssociation> = new EventEmitter<MediaAssociation>();

  //Forms
  @Input() multiLanguageForm!: FormGroup;
  mediaForm?: FormGroup;

  //Enumerations
  enumFileType = fileType;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.multiLanguageForm.get("currentLanguage")?.valueChanges.subscribe((value) => {
      if (value === 0) {
        this.mediaForm?.get("mediaName")?.disable();
        this.mediaForm?.get("mediaName")?.setValue("");
      } else if (value > 0) {
        const mediaNameControl = this.mediaForm?.get(["mediaName"]);
        const mediaTranslation = this.mediaAssociation.mediaAssociationTranslations.find((ma) => ma.languageId === value)?.name;
        if (mediaNameControl) {
          mediaNameControl.setValue(mediaTranslation);
          mediaNameControl.enable();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["mediaAssociation"] && this.mediaForm === undefined) {
      const mediaAssociation = changes["mediaAssociation"].currentValue;
      this.createFormItem(mediaAssociation);
    }
  }

  createFormItem(mediaAssociation: MediaAssociation) {
    const currentLanguage = this.multiLanguageForm.get("currentLanguage")?.value;
    const isLanguageAssigned = currentLanguage === 0;
    let mediaName = "";
    const mediaTranslation = mediaAssociation.mediaAssociationTranslations.find((ma) => ma.languageId === currentLanguage)?.name;
    if (mediaTranslation) {
      mediaName = mediaTranslation;
    }
    this.mediaForm = this.fb.group({
      mediaName: [{ value: mediaName, disabled: isLanguageAssigned }],
      is360: [mediaAssociation.is360],
      order: [mediaAssociation.order],
      isMediaNameTranslated: [mediaAssociation.isMediaNameTranslated],
    });
  }

  displayFileType(type: number): string {
    return displayValue(type);
  }

  start(event: MouseEvent) {
    event.preventDefault();
    this.dragStartedEmitter.emit([event.clientY, event.offsetY, this.mediaAssociation.order]);
  }

  showPopup() {
    this.showAssociatedMediaEmitter.emit(this.mediaAssociation.order - 1);
  }

  removeMedia() {
    this.deleteAssociatedMediaEmitter.emit(this.mediaAssociation.order - 1);
  }

  saveMediaNameTranslation() {
    const mediaName = this.mediaForm?.get("mediaName")?.value;
    const languageId = this.multiLanguageForm.get("currentLanguage")?.value;
    // The property translatedEntityId is used to pass the index of the mediaAssociation to the parent component
    this.renameMediaAssociationEmitter.emit(new MediaAssociationTranslation(this.formArrayIndex, languageId, mediaName));
  }

  saveMediaAssociationInfos() {
    this.saveMediaAssociationInfosEmitter.emit(
      new MediaAssociation(0, this.mediaAssociation.media, this.mediaForm?.get("is360")?.value, this.formArrayIndex, this.mediaForm?.get("isMediaNameTranslated")?.value, []),
    );
  }
}
