import { Component, ElementRef, Input, ViewChildren, QueryList, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { PointOfInterest } from "app/Models/point-of-interest";

@Component({
  selector: "app-point-of-interest-list",
  templateUrl: "./point-of-interest-list.component.html",
  styleUrls: ["./point-of-interest-list.component.css"],
})
export class PointOfInterestListComponent {
  @Input() pointOfInterestList?: PointOfInterest[] = [];
  @Input() itineraryId?: number;
  @Input() isCreation?: boolean;

  @Output() deletePointOfInterestEmitter: EventEmitter<PointOfInterest> = new EventEmitter<PointOfInterest>();
  @Output() navigateNewPointOfInterest: EventEmitter<void> = new EventEmitter<void>();
  @Output() setLocationStartEmitter: EventEmitter<PointOfInterest> = new EventEmitter<PointOfInterest>();

  dragged: boolean = false;
  indexDraggedItem: number = -1;

  mouseCoordinatesY: number = 0;

  mouseCoordMin!: number;
  mouseCoordMax!: number;

  cardPositionYbeforeMoving: number = 0;

  pointerComesFromOutside: boolean = false;

  distanceBetweenCards: number = 100;
  cardHeight!: number;

  @ViewChildren("dragItem", { read: ElementRef }) dragItems!: QueryList<ElementRef>;

  constructor(private router: Router) {}

  start(numberArray: number[]) {
    this.dragged = true;

    this.indexDraggedItem = numberArray[2];

    this.mouseCoordinatesY = numberArray[0];

    const element = this.dragItems.get(this.indexDraggedItem);

    if (element && this.pointOfInterestList) {
      this.cardPositionYbeforeMoving = element.nativeElement.style.top;
      this.cardHeight = element.nativeElement.clientHeight;

      this.mouseCoordMin = this.mouseCoordinatesY - this.indexDraggedItem * this.distanceBetweenCards - numberArray[1];
      this.mouseCoordMax = this.mouseCoordinatesY + (this.pointOfInterestList?.length - this.indexDraggedItem - 1) * this.distanceBetweenCards - numberArray[1] + this.cardHeight;
    }
  }

  move(event: MouseEvent) {
    event.preventDefault();
    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element && this.pointOfInterestList) {
      const newPosY = this.mouseCoordinatesY - event.y;
      this.mouseCoordinatesY = event.y;

      if (this.pointerComesFromOutside == true && (this.mouseCoordinatesY < this.mouseCoordMin + this.cardHeight / 2 || this.mouseCoordinatesY > this.mouseCoordMax - this.cardHeight / 2)) {
        //Pointer already leaved the list and is not able to drag yet
      } else if (this.mouseCoordinatesY < this.mouseCoordMin) {
        // Pointer has left the list from above
        this.pointerComesFromOutside = true;
        element.nativeElement.style.top = 0;
      } else if (this.mouseCoordinatesY > this.mouseCoordMax) {
        // Pointer has left the list from below
        this.pointerComesFromOutside = true;
        element.nativeElement.style.top = this.pointOfInterestList.length * this.distanceBetweenCards - this.distanceBetweenCards + "px";
      } else if (element.nativeElement.offsetTop - newPosY >= 0 && element.nativeElement.offsetTop - newPosY <= (this.dragItems.length - 1) * this.distanceBetweenCards) {
        // Normal drag
        this.pointerComesFromOutside = false;
        element.nativeElement.style.top = element.nativeElement.offsetTop - newPosY + "px";
      }
    }
  }

  stop(event: MouseEvent) {
    event.preventDefault();

    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element) {
      const droppedCoordinatesY = element.nativeElement.offsetTop;

      const dropIndex = Math.round(droppedCoordinatesY / this.distanceBetweenCards);

      if (this.indexDraggedItem != dropIndex && this.pointOfInterestList) {
        const differenceIndex = dropIndex - this.indexDraggedItem;
        const temporaryPointOfInterest: PointOfInterest = this.pointOfInterestList[this.indexDraggedItem];

        if (Math.sign(differenceIndex) == 1) {
          for (let i = 0; i < differenceIndex; i++) {
            this.pointOfInterestList[this.indexDraggedItem + i] = this.pointOfInterestList[this.indexDraggedItem + i + 1];
          }
        } else {
          for (let i = 0; i > differenceIndex; i--) {
            this.pointOfInterestList[this.indexDraggedItem + i] = this.pointOfInterestList[this.indexDraggedItem + i - 1];
          }
        }

        this.pointOfInterestList[dropIndex] = temporaryPointOfInterest;

        const idPointsOfInterestList: number[] = [];

        for (let i = 0; i < this.pointOfInterestList.length; i++) {
          idPointsOfInterestList[i] = this.pointOfInterestList[i].id;
        }
      } else {
        element.nativeElement.style.top = this.cardPositionYbeforeMoving;
      }

      this.affectOrderNumberToPointsOfInterest();

      this.dragged = false;
      this.indexDraggedItem = -1;
    }
  }

  affectOrderNumberToPointsOfInterest() {
    this.pointOfInterestList?.forEach((pointOfInterest, index) => {
      pointOfInterest.order = index + 1;
    });
  }

  // TODO: Improve the way to handle the situation
  onScrollEnd() {
    const element = this.dragItems.get(this.indexDraggedItem);

    if (this.dragged && element && this.pointOfInterestList) {
      element.nativeElement.style.top = this.indexDraggedItem * this.distanceBetweenCards + "px";

      this.dragged = false;

      this.indexDraggedItem = -1;
    }
  }

  getHeightCard(): number {
    if (this.pointOfInterestList && this.pointOfInterestList.length > 0) {
      return this.pointOfInterestList.length * this.distanceBetweenCards + 90;
    } else {
      return 140;
    }
  }

  deletePointOfInterest(pointOfInterest: PointOfInterest) {
    this.deletePointOfInterestEmitter.emit(pointOfInterest);
  }

  navigate() {
    this.navigateNewPointOfInterest.emit();
  }
}
