import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { ApplicationRef } from "@angular/core";
import { enableDebugTools } from "@angular/platform-browser";
import { environment } from "environments/environment";

if (environment.production) {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    })
    .catch((err) => window["console"].error(err));
}
