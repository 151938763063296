import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { entityType } from "app/Enumerations/entity-type.enum";
import { getPublishStateValue, publishState } from "app/Enumerations/publish-state.enum";
import { responseCode } from "app/Enumerations/response-code.enum";
import { PointOfInterestTranslation, PointOfInterestDetail } from "app/Models/PointOfInterestDetail";
import { Language } from "app/Models/language";
import { Module } from "app/Models/module";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { DataService } from "app/Services/Data-Service/data.service";
import { MenuItem, MessageService } from "primeng/api";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-point-of-interest-management-page",
  templateUrl: "./point-of-interest-management-page.component.html",
  styleUrls: ["./point-of-interest-management-page.component.css"],
})
export class PointOfInterestManagementPageComponent implements OnInit {
  pointOfInterest?: PointOfInterestDetail;

  moduleList: Module[] = [];
  availableModuleList: Module[] = [];

  // Translations
  languagesFromItinerary: Language[] = [];
  pointOfInterestTranslations: PointOfInterestTranslation[] = [];
  selectedLanguageId: number = -1;

  //Enumerations
  enumDialogType = confirmDialogType;
  enumEntityType = entityType;
  enumPublishState = publishState;

  // Dialog attributes
  visible: boolean = false;
  confirmTypeAction!: number;
  entityTypeAction!: number;
  idSelected!: string;

  // Forms
  configurationForm: FormGroup;
  multiLanguageForm: FormGroup;

  isCreation: boolean = true;

  //splitButton attributes
  menuItems: MenuItem[] = [];

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.configurationForm = this.fb.group({
      pointOfInterestLabel: [this.pointOfInterest?.identificationName, Validators.required],
      languages: [""],
      latitude: ["", [Validators.min(-90), Validators.max(90)]],
      longitude: ["", [Validators.min(-180), Validators.max(180)]],
      isLocated: [""],
    });

    this.multiLanguageForm = this.fb.group({
      pointOfInterestName: [""],
      currentLanguage: [""],
      details: [""],
    });
  }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    if (routeParams.get("id") != null) {
      this.isCreation = false;
      const PointOfInterestIdFromRoute = String(routeParams.get("id"));

      forkJoin([this.dataService.getPointOfInterest(PointOfInterestIdFromRoute), this.dataService.getLanguages()]).subscribe(([pointOfInterest, languages]) => {
        this.configurationForm.get(["pointOfInterestLabel"])?.setValue(pointOfInterest.identificationName);
        this.configurationForm.get(["isLocated"])?.setValue(pointOfInterest.isLocated);
        this.configurationForm.get(["longitude"])?.setValue(pointOfInterest.longitude);
        this.configurationForm.get(["latitude"])?.setValue(pointOfInterest.latitude);

        if (pointOfInterest.modules) {
          this.moduleList = pointOfInterest.modules;
          this.moduleList.sort((a, b) => a.order - b.order);
        }

        this.pointOfInterest = pointOfInterest;

        if (this.pointOfInterest?.pointOfInterestTranslations && this.pointOfInterest?.pointOfInterestTranslations.length > 0) {
          this.pointOfInterestTranslations = this.pointOfInterest.pointOfInterestTranslations;

          this.multiLanguageForm.get(["pointOfInterestName"])?.setValue(this.pointOfInterestTranslations[0].name);
          this.multiLanguageForm.get(["details"])?.setValue(this.pointOfInterestTranslations[0].details);
          this.multiLanguageForm.get(["currentLanguage"])?.setValue(this.pointOfInterestTranslations[0].languageId);
          this.selectedLanguageId = this.pointOfInterestTranslations[0].languageId;

          languages.forEach((language) => {
            if (this.pointOfInterestTranslations.findIndex((pt) => pt.languageId === language.id) !== -1) {
              this.languagesFromItinerary.push(language);
            }
          });
        }
        this.setPageTitle();
      });

      this.menuItems = [
        {
          label: "Supprimer",
          title: "Supprimer l'étape",
          command: () => {
            this.showDialog(this.enumDialogType.Delete, this.enumEntityType.PointOfInterest, undefined);
          },
        },
      ];
    } else {
      this.dataService.getLanguages().subscribe((languages) => {
        languages.forEach((language) => {
          if (history.state.languageIds.indexOf(language.id) != -1) {
            this.languagesFromItinerary.push(language);
          }
        });
        if (this.languagesFromItinerary.length > 0) {
          this.selectedLanguageId = this.languagesFromItinerary[0].id;
          this.multiLanguageForm.get(["currentLanguage"])?.setValue(this.selectedLanguageId);
          this.pointOfInterestTranslations.push(this.addPoiTranslation(this.selectedLanguageId));
          this.languagesFromItinerary.forEach((language) => {
            if (this.pointOfInterestTranslations.findIndex((poiTranslation) => poiTranslation.languageId == language.id) == -1) {
              this.pointOfInterestTranslations.push(this.addPoiTranslation(language.id));
            }
          });
        }
      });

      this.configurationForm.get(["isLocated"])?.setValue(history.state.isLocated);
    }

    this.dataService.getModules().subscribe((availableModules) => {
      this.availableModuleList = availableModules.filter((modules) => modules.pointOfInterestId == null);
    });
    this.setPageTitle();
  }

  addPoiTranslation(languageIdFromEvent: number): PointOfInterestTranslation {
    return {
      languageId: languageIdFromEvent,
      name: "",
      details: "",
    };
  }

  languageChanged(currentLanguageId: number) {
    this.saveLanguage();

    let indexCurrentLanguage = this.pointOfInterestTranslations.findIndex((e) => e.languageId == currentLanguageId);

    if (indexCurrentLanguage == -1) {
      this.pointOfInterestTranslations.push(this.addPoiTranslation(currentLanguageId));
      indexCurrentLanguage = this.pointOfInterestTranslations.length - 1;
    }

    this.multiLanguageForm.get(["pointOfInterestName"])?.setValue(this.pointOfInterestTranslations[indexCurrentLanguage].name);
    this.multiLanguageForm.get(["details"])?.setValue(this.pointOfInterestTranslations[indexCurrentLanguage].details);

    this.selectedLanguageId = currentLanguageId;
  }

  saveLanguage() {
    const indexLanguageModifications = this.pointOfInterestTranslations.findIndex((e) => e.languageId == this.selectedLanguageId);

    const newLanguageInfo: PointOfInterestTranslation = {
      languageId: this.selectedLanguageId,
      name: this.multiLanguageForm.get(["pointOfInterestName"])?.value,
      details: this.multiLanguageForm.get(["details"])?.value,
    };

    this.pointOfInterestTranslations[indexLanguageModifications] = newLanguageInfo;
  }

  createPointOfInterest() {
    if (this.isConfigurationFormValid()) {
      const newPointOfInterest: PointOfInterestDetail = {
        id: 0,
        itineraryId: history.state.itineraryId,
        identificationName: this.configurationForm.get(["pointOfInterestLabel"])?.value,
        state: this.enumPublishState.NotPublished,
        order: -1,
        longitude: parseFloat(this.configurationForm.get(["longitude"])?.value),
        latitude: parseFloat(this.configurationForm.get(["latitude"])?.value),
        isLocated: history.state.isLocated,
        pointOfInterestTranslations: this.pointOfInterestTranslations,
        modules: this.moduleList,
      };

      if (newPointOfInterest.modules) {
        newPointOfInterest.modules.forEach((module) => {
          // TODO: this.pointOfInterest has already been tested, but an error occurs if we don't test it here
          if (this.pointOfInterest) module.pointOfInterestId = this.pointOfInterest.id;
        });
      }

      this.dataService.createPointOfInterest(newPointOfInterest).subscribe({
        next: (pointOfInterest) => {
          const projectName = this.authService.getProjectName();
          this.messageService.add({ severity: "success", summary: "Création réussie", detail: "L'étape a bien été créée." });
          this.router.navigateByUrl(`/${projectName}/point-of-interest-management/${pointOfInterest.id}`);
        },
        error: (error) => {
          this.messageService.add({ severity: "error", summary: "Création non complétée", detail: "L'étape n'a pas pu être créée." });
          console.log(error);
          console.log(error.error);
        },
      });
    }
  }

  confirmActionDialog(confirmAction: boolean) {
    this.visible = false;
    if (confirmAction == true) {
      if (this.entityTypeAction == this.enumEntityType.PointOfInterest) {
        this.deletePointOfInterest();
      }
    }
  }

  deletePointOfInterest() {
    if (this.pointOfInterest) {
      this.dataService.deletePointOfInterest(this.pointOfInterest.id).subscribe({
        next: (result) => {
          switch (result.errorCode) {
            case responseCode.SuccessfulDeletion:
              this.messageService.add({ severity: "success", summary: "Suppression réussie", detail: "L'étape a bien été supprimée." });
              this.router.navigateByUrl(`/${this.authService.getProjectName()}/itinerary-management/${this.pointOfInterest?.itineraryId}`);
              break;
            case responseCode.ImpossibleDeletion:
              this.messageService.add({ severity: "error", summary: "Suppression impossible", detail: result.errorMessage });
              break;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  isConfigurationFormValid(): boolean {
    if (this.configurationForm.valid) {
      return true;
    }

    if (!this.configurationForm.get(["pointOfInterestLabel"])?.valid) {
      this.messageService.add({ severity: "warn", summary: "Champ invalide", detail: "Le label du parcours est invalide." });
    } else if (!this.configurationForm.get(["longitude"])?.valid) {
      this.messageService.add({ severity: "warn", summary: "Champ invalide", detail: "La longitude du parcours est invalide." });
    } else {
      this.messageService.add({ severity: "warn", summary: "Champ invalide", detail: "La latitude du parcours est invalide." });
    }

    return false;
  }

  displayPointOfInterestState() {
    if (this.pointOfInterest) {
      return getPublishStateValue(this.pointOfInterest.state);
    } else {
      return getPublishStateValue(this.enumPublishState.NotPublished);
    }
  }

  showDialog(confirmTypeAction: number, entityType: number, id?: string) {
    this.confirmTypeAction = confirmTypeAction;
    this.entityTypeAction = entityType;
    if (id) this.idSelected = id;
    this.visible = true;
  }

  updatePointOfInterest() {
    if (this.pointOfInterest && this.isConfigurationFormValid()) {
      this.pointOfInterest.identificationName = this.configurationForm.get(["pointOfInterestLabel"])?.value;
      this.pointOfInterest.pointOfInterestTranslations = this.pointOfInterestTranslations;
      this.pointOfInterest.longitude = this.configurationForm.get(["longitude"])?.value;
      this.pointOfInterest.latitude = this.configurationForm.get(["latitude"])?.value;

      this.pointOfInterest.modules = this.moduleList;

      this.pointOfInterest.modules.forEach((module) => {
        // TODO: this.pointOfInterest has already been tested, but an error occurs if we don't test it here
        if (this.pointOfInterest) module.pointOfInterestId = this.pointOfInterest.id;
      });

      this.dataService.updatePointOfInterest(this.pointOfInterest).subscribe({
        next: (data) => {
          switch (data.errorCode) {
            case responseCode.SuccessfulUpdate:
              this.pointOfInterest = data.data;
              if (data.data.modules) {
                this.moduleList = data.data.modules;
              }
              this.messageService.add({ severity: "success", summary: "Modification réussie", detail: data.errorMessage });
              break;
            case responseCode.NotModified:
              this.messageService.add({ severity: "warn", summary: "Aucune modification", detail: data.errorMessage });
              break;
            case responseCode.ImpossibleUpdate:
              this.messageService.add({ severity: "error", summary: "Modification impossible", detail: data.errorMessage });
              break;
          }
        },
        error: (error) => {
          this.messageService.add({ severity: "error", summary: "Modification non complétée", detail: "L'étape n'a pas pu être modifiée." });
          console.log(error);
        },
      });
    }
  }

  //Module management functions
  addModuleDroped(newModule: Module) {
    this.moduleList.push(newModule);
    const index = this.availableModuleList.findIndex((e) => e.id == newModule.id);
    this.availableModuleList.splice(index, 1);
  }

  removeModule(module: Module) {
    this.availableModuleList.push(module);
    const indexModuleToRemove = this.moduleList.findIndex((moduleFromList) => moduleFromList.id == module.id);
    this.moduleList.splice(indexModuleToRemove, 1);

    for (let i = indexModuleToRemove; i < this.moduleList.length; i++) {
      this.moduleList[i].order = i + 1;
    }
  }

  changePOICoordinates(event: PointOfInterestDetail) {
    if (this.pointOfInterest) {
      this.pointOfInterest.latitude = event.latitude;
      this.pointOfInterest.longitude = event.longitude;
      this.configurationForm.patchValue({ latitude: event.latitude });
      this.configurationForm.patchValue({ longitude: event.longitude });
    }
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }

  setPageTitle() {
    if (this.isCreation) {
      document.title = "Étape - Création";
    } else if (this.pointOfInterest) {
      document.title = "Étape - " + this.pointOfInterest.identificationName;
    }
  }
}
