import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { inputLength } from "app/ConfigVariables/input-length";
import { PointOfInterestTranslation } from "app/Models/PointOfInterestDetail";
import { Language } from "app/Models/language";

@Component({
  selector: "app-point-of-interest-multi-language-content",
  templateUrl: "./point-of-interest-multi-language-content.component.html",
  styleUrls: ["./point-of-interest-multi-language-content.component.css"],
})
export class PointOfInterestMultiLanguageContentComponent {
  @Input() multiLanguageForm: FormGroup;

  @Input() languagesSelected: Language[] = [];

  @Input() LanguageInfosPointOfInterest: PointOfInterestTranslation[] = [];

  @Output() languageChangedEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveLanguageEmitter: EventEmitter<void> = new EventEmitter<void>();

  inputLength = inputLength;

  constructor(private fb: FormBuilder) {
    this.multiLanguageForm = this.fb.group({
      pointOfInterestName: [""],
      currentLanguage: [""],
      details: [""],
    });
  }

  setLanguage(languageSelected: number) {
    this.languageChangedEmitter.emit(languageSelected);
  }

  saveLanguage() {
    this.saveLanguageEmitter.emit();
  }

  showLanguageState(languageId: number): boolean {
    const indexLanguage = this.LanguageInfosPointOfInterest.findIndex((poiTranslation) => poiTranslation.languageId == languageId);

    if (indexLanguage == -1 || this.LanguageInfosPointOfInterest[indexLanguage].name == "" || this.LanguageInfosPointOfInterest[indexLanguage].details == "") {
      return false;
    }

    return true;
  }

  countNameCharacters(): string {
    return this.multiLanguageForm.get(["pointOfInterestName"])?.value.length;
  }

  countDetailsCharacters(): string {
    return this.multiLanguageForm.get(["details"])?.value.length;
  }
}
