<div class="card">
  <form [formGroup]="moduleForm">
    <h2>Activité spéciale</h2>
    <p class="margin-bottom">Choisissez un type d'activité à lier. Ces activités ne sont pas modifiables depuis le CMS et sont directement inclues dans l'application.</p>
    <p-dropdown
      [options]="moduleCustomTemplates"
      formControlName="moduleCustomTemplateId"
      optionValue="id"
      optionLabel="name"
      placeholder="Sélectionner un type d'activité à lier"
      emptyMessage="Aucune activité spéciale disponible"
      scrollHeight="400px"
      filter="true"
      (onChange)="selectModuleTemplate($event.value)">
    </p-dropdown>
  </form>
</div>
