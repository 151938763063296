import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DataService } from "../Data-Service/data.service";
import { HttpEventType } from "@angular/common/http";
import { Media } from "app/Models/media";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UploadFilesService {
  private filesToUpload = new BehaviorSubject<File[]>([]);
  private uploadProgressArray = new BehaviorSubject<number[]>([]);
  private uploadInProgress = new BehaviorSubject<boolean>(false);
  private uploadFinished = new BehaviorSubject<boolean>(false);
  private visiblePopup = new BehaviorSubject<boolean>(false);
  private newMedia = new BehaviorSubject<Media | null>(null);

  uploadProgressArray$ = this.uploadProgressArray.asObservable();
  filesToUpload$ = this.filesToUpload.asObservable();
  uploadInProgress$ = this.uploadInProgress.asObservable();
  uploadFinished$ = this.uploadFinished.asObservable();
  visiblePopup$ = this.visiblePopup.asObservable();
  newMedia$ = this.newMedia.asObservable();

  uploadStarted: boolean = false;

  allowedFileTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg", "audio/mpeg", "audio/aiff", "audio/wav", "video/quicktime", "video/mp4"];

  uploadedFilesCount: number = 0;

  cancelUploadArray: Subject<void>[] = [];
  cancelledArray: boolean[] = [];

  constructor(private dataService: DataService) {}

  setfiles(files: File[]) {
    this.filesToUpload.next(files);
    this.uploadProgressArray.next(Array<number>(files.length).fill(0));
    this.cancelUploadArray = files.map(() => new Subject<void>());
    this.cancelledArray = Array(files.length).fill(false);
  }

  uploadFiles() {
    this.uploadInProgress.next(true);
    this.visiblePopup.next(true);
    this.uploadRecursive(0);
  }

  uploadRecursive(fileIndex: number) {
    if (fileIndex >= this.filesToUpload.value.length) {
      this.resetUpload();
      return;
    }

    const currentFileInfo = this.filesToUpload.value[fileIndex];

    if (this.cancelledArray[fileIndex]) {
      this.uploadRecursive(fileIndex + 1);
      return;
    }

    const fd = new FormData();
    fd.append("file", currentFileInfo);

    this.dataService
      .uploadMedia(fd)
      .pipe(takeUntil(this.cancelUploadArray[fileIndex]))
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            this.uploadProgressArray.value[fileIndex] = Math.trunc((event.loaded / event.total) * 100);
          }
          if (event.type === HttpEventType.Response) {
            this.uploadedFilesCount++;
            this.newMedia.next(event.body);
            this.newMedia.next(null);
          }
        },
        error: (error) => {
          console.log("Erreur lors de l'upload du fichier : ", error.message);
          this.uploadedFilesCount++;
          this.uploadRecursive(this.uploadedFilesCount);
        },
        complete: () => {
          this.uploadRecursive(fileIndex + 1);
        },
      });
  }

  cancelUpload(index: number) {
    const currentFiles = this.filesToUpload.value;
    if (currentFiles[index]) {
      this.cancelledArray[index] = true;
      this.cancelUploadArray[index].next();
    }
  }

  resetUpload() {
    this.filesToUpload.next([]);
    this.uploadInProgress.next(false);
    this.uploadFinished.next(true);
    this.uploadedFilesCount = 0;
  }

  setVisiblePopup(isVisible: boolean) {
    this.visiblePopup.next(isVisible);
  }
}
