export enum moduleType {
  Undefined = -1,
  Custom = 0,
  Gallery = 1,
  QuizTrueFalse = 2,
  QuizMultipleChoice = 3,
  QuizIncremental = 4,
}

export function moduleTypeToString(enumValue: moduleType): string {
  switch (enumValue) {
    case moduleType.Gallery:
      return "Galerie multimédia";
    case moduleType.QuizTrueFalse:
      return "Quiz vrai/faux";
    case moduleType.QuizMultipleChoice:
      return "Questionnaire";
    case moduleType.QuizIncremental:
      return "Quiz incrémental";
    case moduleType.Custom:
      return "Custom";
    default:
      return "Type inconnu";
  }
}

export function getModuleTypeURL(enumValue: moduleType): string {
  switch (enumValue) {
    case moduleType.Gallery:
      return "gallery";
    case moduleType.QuizTrueFalse:
      return "true-false";
    case moduleType.QuizMultipleChoice:
      return "quiz";
    case moduleType.Custom:
      return "custom";
    default:
      return "unknown";
  }
}
