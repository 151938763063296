import { Component, EventEmitter, Input, Output } from "@angular/core";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { entityType } from "app/Enumerations/entity-type.enum";
import { getPublishStateValue, publishState } from "app/Enumerations/publish-state.enum";
import { Itinerary } from "app/Models/itinerary";
import { AuthService } from "app/Services/Auth-Service/auth.service";

@Component({
  selector: "app-itinerary-card",
  templateUrl: "./itinerary-card.component.html",
  styleUrls: ["./itinerary-card.component.css"],
})
export class ItineraryCardComponent {
  @Input() itinerary!: Itinerary;

  @Output() deleteItineraryEmitter: EventEmitter<number> = new EventEmitter<number>();

  // Dialog attributes
  visible: boolean = false;
  confirmTypeAction!: number;
  entityTypeAction!: number;

  //Enumerations
  enumDialogType = confirmDialogType;
  enumEntityType = entityType;
  enumPublishState = publishState;

  constructor(private authService: AuthService) {}

  showDialog(confirmTypeAction: number, entityType: number) {
    this.confirmTypeAction = confirmTypeAction;
    this.entityTypeAction = entityType;
    this.visible = true;
  }

  deleteItinerary() {
    this.visible = false;
    this.deleteItineraryEmitter.emit(this.itinerary?.id);
  }

  confirmActionDialog(confirmAction: boolean) {
    this.visible = false;
    if (confirmAction == true) {
      this.deleteItinerary();
    }
  }

  displayPointOfInterestState() {
    return getPublishStateValue(this.itinerary.state);
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
