<div class="card" [style.height.px]="getHeightCard()">
  @if (dragged) {
    <div (window:mouseup)="stop($event)" (window:mousemove)="move($event)"></div>
  }
  <div class="added-module-list">
    <h2>Activités</h2>

    <div class="item-container" [style.height.px]="distanceBetweenCards * moduleList.length">
      @for (module of moduleList; track module.id; let indexOfelement = $index) {
        <app-added-module-card
          #dragItem
          class="item"
          [ngClass]="indexOfelement === indexDraggedItem ? 'bring-to-front' : 'set-to-back'"
          [style.top.px]="indexOfelement * distanceBetweenCards"
          [module]="module"
          (removeModuleEmitter)="removeModule($event)"
          (dragStartedEmitter)="start($event)">
        </app-added-module-card>
      }
    </div>

    <div class="dropzone" (drop)="addModuleDroped($event)" (dragover)="$event.preventDefault()">
      <p class="align-center">Déposez l'activité que vous souhaitez associer dans cette zone.</p>
    </div>
  </div>

  <div class="available-module-list">
    <div class="header">
      <h3>Activités disponibles</h3>
      <a aria-hidden="true" (click)="navigateNewModule()" (keypress)="navigateNewModule()" title="Créer une nouvelle activité" class="action-buttons-item">
        <i class="bi bi-plus-circle-dotted"></i>
      </a>
    </div>

    <div class="scrollable-section" [style.height.px]="getHeightCard() - 100">
      @for (module of availableModuleList; track module) {
        <app-available-module-card draggable="true" (dragstart)="addModuleDragStarted(module)" [module]="module"> </app-available-module-card>
      }
    </div>
  </div>
</div>
