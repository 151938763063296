<div class="body">
  <div class="page">
    <h1>Changelog</h1>

    <p class="margin-p">Le changelog vous permet de suivre les nouveautés, les améliorations et les corrections effectuées sur l'application.</p>

    @if (!environment.production) {
      <h2 class="blue-color">Unreleased</h2>
      <ul>
        @for (item of unreleasedFuntionalitiesList; track $index) {
          @if (item.functionalities.added.length > 0) {
            <h4>Ajouté :</h4>
            <ul>
              @for (description of item.functionalities.added; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
          @if (item.functionalities.changed.length > 0) {
            <h4>Modifié :</h4>
            <ul>
              @for (description of item.functionalities.changed; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
          @if (item.functionalities.fixed.length > 0) {
            <h4>Corrigé :</h4>
            <ul>
              @for (description of item.functionalities.fixed; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
          @if (item.functionalities.removed.length > 0) {
            <h4>Supprimé :</h4>
            <ul>
              @for (description of item.functionalities.removed; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
        }
      </ul>
    }
    <h2 class="blue-color">Nouvelles fonctionnalités :</h2>

    <ul>
      @for (item of changelogItems; track $index) {
        <li class="new-functionality">
          <h3>{{ item.releaseDate | date: "yyyy-MM-dd" }}:</h3>
          @if (item.functionalities.added.length > 0) {
            <h4>Ajouté :</h4>
            <ul>
              @for (description of item.functionalities.added; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
          @if (item.functionalities.changed.length > 0) {
            <h4>Modifié :</h4>
            <ul>
              @for (description of item.functionalities.changed; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
          @if (item.functionalities.fixed.length > 0) {
            <h4>Corrigé :</h4>
            <ul>
              @for (description of item.functionalities.fixed; track $index) {
                <li>{{ description }}</li>
              }
            </ul>
          }
          @if (item.functionalities.removed.length > 0) {
            <ul>
              @for (description of item.functionalities.removed; track $index) {
                <h4>Supprimé :</h4>
                <li>{{ description }}</li>
              }
            </ul>
          }
        </li>
      }
    </ul>
  </div>
</div>
