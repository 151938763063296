import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { inputLength } from "app/ConfigVariables/input-length";
import { moduleTypeToString, moduleType } from "app/Enumerations/module-type.enum";
import { Language } from "app/Models/language";
import { Module } from "app/Models/module";
import { PointOfInterest } from "app/Models/point-of-interest";
import { ModuleService } from "app/Services/Module-Service/module.service";
import { MessageService } from "primeng/api";
import { MultiSelectChangeEvent } from "primeng/multiselect";

@Component({
  selector: "app-module-configuration",
  templateUrl: "./module-configuration.component.html",
  styleUrl: "./module-configuration.component.css",
})
export class ModuleConfigurationComponent implements OnInit, OnChanges {
  @Input() pointOfInterestList: PointOfInterest[] = [];

  @Input() languagesList: Language[] = [];

  isPOIAssociated: boolean = false;

  // Forms
  moduleForm: FormGroup;

  //Enumerations
  enumModuleType = moduleType;

  inputLength = inputLength;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private moduleService: ModuleService,
  ) {
    this.moduleForm = this.fb.group({
      moduleLabel: ["", [Validators.maxLength(inputLength.maxModuleLabel), Validators.required]],
      moduleType: [""],
      associatedPOI: [""],
      languages: [""],
      languagesFromPOI: [""],
    });
  }

  ngOnInit() {
    this.moduleService.moduleObservable.subscribe((module) => {
      this.moduleForm.get("moduleLabel")?.setValue(module.identificationName);
      this.moduleForm.get("moduleType")?.setValue(module.type);
      this.moduleForm.get("associatedPOI")?.setValue(module.pointOfInterestId);

      if (module.pointOfInterestId) {
        this.isPOIAssociated = true;
      }

      if (module.languagesAssociated) {
        this.moduleForm.get(["languages"])?.setValue(module.languagesAssociated);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (history.state.pointOfInterestId && changes["pointOfInterestList"] && changes["pointOfInterestList"].currentValue.length > 0) {
      this.pointOfInterestAssociated(history.state.pointOfInterestId);
    }
  }

  pointOfInterestAssociated(pointOfInterestId: number) {
    this.isPOIAssociated = true;

    const partialUpdate: Partial<Module> = {
      pointOfInterestId: pointOfInterestId,
    };
    this.moduleService.savePartialUpdate(partialUpdate);

    // TODO: be careful with the module order
    // TODO: order the languages in the same order
    // this.pointOfInterestAssociatedEmitter.emit(pointOfInterestId);

    const languagesPoi = this.pointOfInterestList.filter((p) => p.id === pointOfInterestId)[0].languageIds;

    const languagesFromForm: number[] = this.moduleForm.get("languages")?.getRawValue();
    const languageIds: number[] = [];
    for (let i = 0; i < languagesFromForm.length; i++) {
      languageIds.push(languagesFromForm[i]);
    }

    for (let i = 0; i < languagesPoi.length; i++) {
      if (!languagesFromForm.includes(languagesPoi[i])) {
        languageIds.push(languagesPoi[i]);
        this.moduleService.modifyLanguageInService(languagesPoi[i]);
      }
    }
    languageIds.sort();
    this.moduleForm.get(["languages"])?.setValue(languageIds);
  }

  configurationLanguageChange(event: MultiSelectChangeEvent) {
    if (event.itemValue != null) {
      const language = event.itemValue as Language;

      const indexPointOfInterest = this.pointOfInterestList.findIndex((poi) => poi.id === this.moduleForm.get(["associatedPOI"])?.value);

      const languagesFromForm = this.moduleForm.get(["languages"]);
      if (indexPointOfInterest != -1 && this.pointOfInterestList[indexPointOfInterest].languageIds.indexOf(language.id) != -1 && languagesFromForm) {
        this.messageService.clear();
        this.messageService.add({ severity: "warn", summary: "Suppression non autorisée", detail: "Cette langue est héritée de l'étape associé." });
        languagesFromForm.setValue(this.setLanguagesForm(languagesFromForm.value, language.id));
      } else {
        this.moduleService.modifyLanguageInService(language.id);
      }
    }
  }

  setLanguagesForm(languageIds: number[], newLanguageId: number): number[] {
    languageIds.push(newLanguageId);
    languageIds.sort();
    return languageIds;
  }

  removePointOfInterestAssociation() {
    this.isPOIAssociated = false;

    const partialUpdate: Partial<Module> = {
      pointOfInterestId: undefined,
    };
    this.moduleService.savePartialUpdate(partialUpdate);
  }

  displayModuleType(): string {
    return moduleTypeToString(this.moduleForm.get(["moduleType"])?.value);
  }

  saveModuleLabel() {
    const partialUpdate: Partial<Module> = {
      identificationName: this.moduleForm.get("moduleLabel")?.value,
    };
    this.moduleService.savePartialUpdate(partialUpdate);
  }

  countLabelCharacters(): string {
    const moduleLabel = this.moduleForm.get(["moduleLabel"])?.value;
    if (moduleLabel) {
      return moduleLabel.length;
    } else {
      return "0";
    }
  }

  isModuleLabelInvalid() {
    const moduleLabel = this.moduleForm.get(["moduleLabel"]);
    return moduleLabel?.invalid && moduleLabel?.value && moduleLabel.value.length > 40;
  }

  isModuleDirty() {
    const moduleLabel = this.moduleForm.get(["moduleLabel"]);
    return moduleLabel?.invalid && moduleLabel?.dirty;
  }
}
