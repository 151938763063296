import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "app/Services/Auth-Service/auth.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  extended: boolean = true;
  userType!: string | null;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userType = this.authService.getUserType();
    const storedState = localStorage.getItem("navbarExtendedState");
    if (storedState) {
      this.extended = JSON.parse(storedState);
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }

  setExtended(state: boolean) {
    this.extended = state;
    localStorage.setItem("navbarExtendedState", JSON.stringify(this.extended));
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
