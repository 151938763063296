<div class="body">
  <div class="page">
    <h1>Administration</h1>
    <div class="horizontal-display">
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.Projects"
        (click)="changeTab(enumAdministrationType.Projects)"
        (keypress)="changeTab(enumAdministrationType.Projects)">
        Projets
      </p>
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.Users"
        (click)="changeTab(enumAdministrationType.Users)"
        (keypress)="changeTab(enumAdministrationType.Users)">
        Utilisateurs
      </p>
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.ModuleCustom"
        (click)="changeTab(enumAdministrationType.ModuleCustom)"
        (keypress)="changeTab(enumAdministrationType.ModuleCustom)">
        Modules spéciaux
      </p>
      <p
        class="tab-section"
        aria-hidden="true"
        [class.selected]="administrationType === enumAdministrationType.Languages"
        (click)="changeTab(enumAdministrationType.Languages)"
        (keypress)="changeTab(enumAdministrationType.Languages)">
        Langues
      </p>
    </div>
    <div class="content">
      @switch (administrationType) {
        @case (enumAdministrationType.Projects) {
          @if (modeCreation) {
            <div class="form-container">
              <h3>Nouveau projet</h3>
              <div>
                <span>Nom du projet:</span>
                <input pInputText id="projectName" [formControl]="projectName" />
              </div>
              <div>
                <span>Plateformes:</span>
                <p-multiSelect
                  [showHeader]="false"
                  [options]="buildPlatforms"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Choisir une/des plateformes de build"
                  emptyMessage="Aucune plateforme de build n'a été trouvée."
                  [formControl]="projectBuildPlatforms">
                </p-multiSelect>
              </div>
              <button class="button margin-left" (click)="createProject()">Créer</button>
            </div>
          } @else {
            <button class="button" (click)="modeCreation = true">Créer un projet</button>
          }
          <table>
            <tr>
              <th>Id</th>
              <th class="long-columns-projects-table">Nom</th>
              <th class="long-columns-projects-table">Plateformes de build</th>
              <th class="align-center">Actions</th>
            </tr>
            @for (project of projects; let indexProject = $index; track indexProject) {
              <tr>
                <td class="table-project-item">{{ project.id }}</td>
                @if (indexProjectToModify !== indexProject) {
                  <td class="table-project-item">{{ project.identificationName }}</td>
                  <td>
                    @if (project.buildPlatforms.length > 0) {
                      @for (buildPlatform of project.buildPlatforms; let indexBuildPlatform = $index; track indexBuildPlatform) {
                        <div class="horizontal-display">{{ buildPlatform.name }}</div>
                      }
                    } @else {
                      Aucune plateforme n'est associée au projet.
                    }
                  </td>
                  <td class="align-center">
                    <i tabIndex="0" aria-hidden="true" class="bi bi-pencil btn-action-file" title="Renommer" (click)="modifyProject(indexProject)" (keypress)="modifyProject(indexProject)"> </i>
                  </td>
                } @else {
                  <td>
                    <input pInputText id="projectNameTable" [formControl]="projectNameTable" />
                  </td>
                  <td class="long-columns-projects-table">
                    <p-multiSelect
                      [style]="{ width: '100%' }"
                      [showHeader]="false"
                      [options]="buildPlatforms"
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Choisir une/des plateformes de build"
                      emptyMessage="Aucune plateforme de build n'a été trouvée."
                      [formControl]="projectBuildPlatformsTable">
                      <ng-template let-value pTemplate="selectedItems">
                        <div class="elements-p-multiselect">
                          @for (option of value; let optionIndex = $index; track optionIndex) {
                            <p class="truncate-one-line">{{ option.name }}</p>
                            <p>
                              @if (optionIndex !== value.length - 1) {
                                ,
                              }
                            </p>
                          }
                          @if (!value || value.length === 0) {
                            Choisir une plateforme
                          }
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </td>
                  <td class="align-center">
                    <i tabIndex="0" aria-hidden="true" class="bi bi-check-lg btn-action-file" title="Confirmer" (click)="updateProject()" (keypress)="confirmProjectModification()"> </i>
                    <i
                      tabIndex="0"
                      aria-hidden="true"
                      class="bi bi-x-lg btn-action-file"
                      title="Abandonner les changements"
                      (click)="cancelProjectModification()"
                      (keypress)="cancelProjectModification()">
                    </i>
                  </td>
                }
              </tr>
            }
          </table>
        }
        @case (enumAdministrationType.Users) {
          @if (modeCreation) {
            <form [formGroup]="userForm">
              <div class="form-container">
                <h3>Nouvel utilisateur</h3>
                <div class="form-field">
                  <label htmlFor="userName">Nom d'utilisateur</label>
                  <!-- <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p> -->
                  <input pInputText id="userName" formControlName="username" />
                </div>

                <div class="form-field">
                  <label htmlFor="password">Saisir le mot de passe</label>
                  <!-- <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p> -->
                  <p-password id="password" formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
                </div>

                <div class="form-field">
                  <label htmlFor="secondPassword">Confirmer le mot de passe</label>
                  <!-- <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p> -->
                  <p-password id="secondPassword" formControlName="secondPassword" [feedback]="false" [toggleMask]="true"></p-password>
                </div>

                <div class="form-field">
                  <label htmlFor="itineraryName">Adresse email</label>
                  <!-- <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p> -->
                  <input pInputText id="emailAddress" formControlName="emailAddress" />
                </div>

                <div class="form-field">
                  <label htmlFor="role">Rôle de l'utilisateur</label>
                  <!-- <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p> -->
                  <p-dropdown id="role" [options]="rolesList" formControlName="role" placeholder="Choisir un rôle"> </p-dropdown>
                </div>

                <div class="form-field">
                  <label htmlFor="projectAssociated">Projet associé</label>
                  <!-- <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p> -->
                  <p-dropdown id="projectAssociated" [options]="projects" optionId="id" optionLabel="identificationName" formControlName="projectAssociated" placeholder="Choisir un projet">
                  </p-dropdown>
                </div>
                <button class="button" (click)="createUser()">Ajouter</button>
              </div>
            </form>
          } @else {
            <button class="button" (click)="modeCreation = true">Ajouter un utilisateur</button>
          }
          <table>
            <tr>
              <th>Id</th>
              <th>Nom</th>
              <th>Adresse e-mail</th>
              <th>Role</th>
              <th>Project</th>
            </tr>
            @for (user of users; track $index) {
              <tr>
                <td class="table-user-item">{{ user.id }}</td>
                <td class="table-user-item">{{ user.username }}</td>
                <td class="table-user-item">{{ user.emailAddress }}</td>
                <td class="table-user-item">{{ user.role }}</td>
                <td class="table-user-item">{{ user.projects[0].identificationName }}</td>
              </tr>
            }
          </table>
        }
        @case (enumAdministrationType.ModuleCustom) {
          @if (modeCreation) {
            <div class="form-container">
              <h3>Nouveau module spécial</h3>
              <form [formGroup]="newModuleCustomForm">
                <label htmlFor="moduleKey">Clef du module</label>
                <input pInputText id="moduleKey" formControlName="moduleKey" />
                <label htmlFor="name">Nom du module</label>
                <input pInputText id="name" formControlName="name" />
              </form>
              <button class="button margin-left" (click)="createModuleCustom()">Créer</button>
            </div>
          } @else {
            <button class="button" (click)="modeCreation = true">Créer un module spécial</button>
          }
          <form [formGroup]="moduleCustomForm">
            <button class="button" (click)="saveModuleAssociations()">Sauvegarder</button>
            <table>
              <thead class="membership-tiers text-left">
                <tr>
                  <th>Nom</th>
                  <th>Clé</th>
                  @for (project of projects; track $index; let columnIndex = $index) {
                    <th class="rotated-text" scope="col">{{ project.identificationName }}</th>
                  }
                </tr>
              </thead>

              <tbody formArrayName="moduleCustomRows">
                @for (module of moduleCustomList; let rowIndex = $index; track rowIndex) {
                  <tr [formGroupName]="rowIndex">
                    <td>{{ module.name }}</td>
                    <td>{{ module.moduleKey }}</td>
                    @for (project of projects; let columnIndex = $index; track $index) {
                      <td>
                        <div class="checkbox" formArrayName="columns">
                          <p-checkbox [binary]="true" [formControlName]="columnIndex" (click)="messageCheckbox(rowIndex, columnIndex)"></p-checkbox>
                        </div>
                      </td>
                    }
                  </tr>
                }
              </tbody>

              <!-- <tbody>
                  @for (module of moduleCustomList; let rowIndex = $index; track rowIndex) {
                    <tr [formGroupName]="rowIndex">
                      <p>{{ rowIndex }}</p>
                      <th>
                        <p>{{ module.name }}</p>
                      </th>
                      @for (project of projects; let columnIndex = $index; track $index) {
                        <td>
                          <p>{{ columnIndex }}</p>

                          <div class="checkbox" formArrayName="columns">
                            <p-checkbox [formGroupName]="columnIndex"></p-checkbox>
                          </div>
                        </td>
                      }
                    </tr>
                  }
                </tbody> -->
            </table>
          </form>
        }
        @case (enumAdministrationType.Languages) {
          <p>La gestion des langues n'est pas disponible pour le moment.</p>

          <table>
            <tr>
              <th>Id</th>
              <th>Langue</th>
              <th>Code</th>
            </tr>
            @for (language of languages; track $index) {
              <tr>
                <td class="table-user-item">{{ language.id }}</td>
                <td class="table-user-item">{{ language.name }}</td>
                <td class="table-user-item">{{ language.code }}</td>
              </tr>
            }
          </table>
        }
      }
    </div>
  </div>
</div>

<app-module-custom-popup
  [visible]="visiblePopup"
  [moduleCustomAssociations]="modifications"
  [projects]="projects"
  [modules]="moduleCustomList"
  (confirmActionEmitter)="processModuleAssociations($event)">
</app-module-custom-popup>
