import { Component, Input } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Language } from "app/Models/language";

@Component({
  selector: "app-module-quiz",
  templateUrl: "./module-quiz.component.html",
  styleUrl: "./module-quiz.component.css",
})
export class ModuleQuizComponent {
  @Input() languagesSelected: Language[] = [];

  constructor(private fb: FormBuilder) {}
}
