export enum publishState {
  NotPublished = 1,
  PublicationInProgress = 2,
  Published = 3,
  Modified = 4,
}

export function getPublishStateValue(enumValue: publishState): string {
  switch (enumValue) {
    case publishState.Published:
      return "publié";
    case publishState.Modified:
      return "modifié";
    case publishState.NotPublished:
      return "non publié";
    case publishState.PublicationInProgress:
      return "publication en cours";
    default:
      return "état inconnu";
  }
}
