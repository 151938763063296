import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-not-found-page",
  templateUrl: "./not-found-page.component.html",
  styleUrl: "./not-found-page.component.css",
})
export class NotFoundPageComponent implements OnInit {
  ngOnInit() {
    document.title = "Page introuvable";
  }
}
