<div class="body">
  <form [formGroup]="loginForm">
    <h1 id="login-text">Login</h1>

    <div class="form-field">
      <span class="p-float-label">
        <input pInputText id="username" formControlName="username" />
        <label htmlFor="username">Nom d'utilisateur</label>
      </span>
    </div>
    <div class="form-field">
      <span class="p-float-label">
        <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
        <label for="password">Mot de passe</label>
      </span>
    </div>
    <div class="form-button">
      <button [disabled]="isLogging" class="button" (click)="login()">Login</button>
      @if (isLogging) {
        <p-progressSpinner class="spinner"></p-progressSpinner>
      }
    </div>
  </form>
</div>
