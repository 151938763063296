<div class="card">
  <form [formGroup]="multiLanguageForm">
    <div class="header">
      <h2 title="Cette section sert à configurer le contenu qui sera visible par l'utilisateur.">
        Contenu de l'application<i class="bi bi-question-circle infos-title-section margin-left-small"></i>
      </h2>
      @for (language of languagesSelected; track language) {
        <app-language-state [language]="language" [languageState]="showLanguageState(language.id)"> </app-language-state>
      }
    </div>

    @if (itineraryTranslation.length === 0) {
      <p>Veuillez ajouter une langue pour pouvoir ajouter du contenu au parcours</p>
    } @else {
      <div class="horizontal-display">
        <div class="vertical-display">
          <div class="form-field">
            <label htmlFor="itineraryName">Langue en cours de modification</label>
            <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p>
            <p-dropdown [options]="languagesSelected" optionValue="id" optionLabel="name" formControlName="currentLanguage" (onChange)="setLanguage($event.value)"> </p-dropdown>
          </div>
          <div class="form-field">
            <span class="">
              <label htmlFor="itineraryName">Nom du parcours</label>
              <p class="field-explanation">Comment souhaitez-vous nommer votre parcours</p>
              <input pInputText id="itineraryName" formControlName="itineraryName" (change)="saveLanguage()" [maxlength]="inputLength.maxItineraryName" />
              <small class="char-count"> ({{ countNameCharacters() }}/{{ inputLength.maxItineraryName }}) </small>
            </span>
          </div>
        </div>
        <div class="vertical-display form-field">
          <span class="">
            <label for="details ">Détails du parcours</label>
            <p class="field-explanation">Renseignez les informations générales du parcours</p>
            <textarea id="details" rows="5" pInputTextarea formControlName="details" [autoResize]="true" (change)="saveLanguage()" [maxlength]="inputLength.maxItineraryDetails"></textarea>
            <small class="char-count"> ({{ countDetailsCharacters() }}/{{ inputLength.maxItineraryDetails }}) </small>
          </span>
        </div>
      </div>
    }
  </form>
</div>
