export enum fileType {
  Pdf = 1,
  Video = 2,
  Image = 3,
  Audio = 4,
}

export function displayValue(enumValue: fileType): string {
  switch (enumValue) {
    case fileType.Pdf:
      return "Pdf";
    case fileType.Video:
      return "Vidéo";
    case fileType.Image:
      return "Image";
    case fileType.Audio:
      return "Audio";
    default:
      return "Type inconnu";
  }
}
