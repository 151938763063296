import { Component, Input } from "@angular/core";
import { moduleTypeToString } from "app/Enumerations/module-type.enum";
import { Module } from "app/Models/module";

@Component({
  selector: "app-available-module-card",
  templateUrl: "./available-module-card.component.html",
  styleUrls: ["./available-module-card.component.css"],
})
export class AvailableModuleCardComponent {
  @Input() module!: Module;

  displayModuleType() {
    return moduleTypeToString(this.module.type);
  }
}
