@if (mediaForm) {
  <form [formGroup]="mediaForm">
    <div class="card-media">
      <div class="horizontal-display full-width">
        <i aria-hidden="true" (mousedown)="start($event)" class="drag-drop-icon bi bi-grip-horizontal" title="Glisser et déposer pour réorganiser l'ordre des médias"> </i>

        <div class="horizontal-display full-width">
          <p class="truncate-one-line media-name" title="Nom du média: {{ mediaAssociation.media.name }}">{{ mediaAssociation.media.name }}</p>
          <div class="media-translation">
            <p-checkbox class="checkbox" [binary]="true" title="Traduire le nom du média" formControlName="isMediaNameTranslated" (onChange)="saveMediaAssociationInfos()"></p-checkbox>
            @if (mediaAssociation.isMediaNameTranslated) {
              <input class="margin-right" #mediaInputLabel pInputText formControlName="mediaName" (change)="saveMediaNameTranslation()" />
            } @else {
              <p class="checkbox-translation-text truncate-two-lines" title="Traduire le nom du média">Traduire le nom du média</p>
            }
          </div>
          <div class="horizontal-display file-type">
            <p class="margin-right">{{ displayFileType(mediaAssociation.media.fileType) }}</p>
            @if (mediaAssociation.media.fileType === enumFileType.Image) {
              <p-checkbox [binary]="true" formControlName="is360" class="checkbox margin-left" [value]="mediaAssociation.is360" (onChange)="saveMediaAssociationInfos()"></p-checkbox>
              <p>360°</p>
            }
          </div>
        </div>
      </div>
      <div class="horizontal-display">
        <i tabIndex="0" aria-hidden="true" class="bi bi-eye action-buttons-item margin-right" title="Visualiser le média" (click)="showPopup()"> </i>
        <i
          tabIndex="0"
          aria-hidden="true"
          class="bi bi-x-lg action-buttons-item"
          title="Supprimer l'association du média"
          (click)="removeMedia(); $event.stopPropagation()"
          (keypress)="removeMedia(); $event.stopPropagation()">
        </i>
      </div>
    </div>
  </form>
}
