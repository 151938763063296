<div
  class="language-state"
  [ngClass]="{
    'language-state-small-size': isSmall,
    'language-state-normal-size': !isSmall,
    'green-language-state': languageState,
    'red-language-state': !languageState,
  }"
  [title]="'Langue ' + language.name + (languageState === true ? ' complète' : ' incomplète')">
  <span>{{ language.code | uppercase }}</span>
</div>
