<div class="module-card">
  <div class="horizontal-display">
    <i aria-hidden="true" (mousedown)="dragStarted($event)" class="icon-drag-drop bi bi-grip-horizontal" title="Glisser et déposer pour réorganiser l'ordre des activités"> </i>

    <div>
      <h3 [title]="'Label de l\'activité : ' + module.identificationName" class="truncate-two-lines">
        <a routerLink="/{{ this.getProjectName() }}/module-management/{{ this.module.id }}">{{ module.identificationName }}</a>
      </h3>
      <p title="Type de l\'activité">{{ displayModuleType() }}</p>
    </div>
  </div>

  <div class="right-section">
    @if (module.type === enumModuleType.Gallery) {
      <div class="infos-module">
        <div class="infos-module-item" title="Nombre de documents associés à l'activité.">
          <p>{{ module.mediaCount || 0 }}</p>
          <i class="bi bi-file-earmark infos-module-icon"></i>
        </div>
      </div>
    }

    <div class="horizontal-display">
      <app-publication-state class="single-line" [publicationState]="module.state" [entityType]="enumEntityType.Module"> </app-publication-state>
      @if (module.pointOfInterestId !== null) {
        <app-completion-state class="margin-left" [completionState]="module.readyForPublication" [tooltipTrue]="'L\'activité est complète'" [tooltipFalse]="'L\'activité n\'est pas complète'">
        </app-completion-state>
      }
    </div>

    <div class="action-buttons">
      <a routerLink="/{{ this.getProjectName() }}/module-management/{{ this.module.id }}" title="Modifier" class="action-buttons-item">
        <i class="bi bi-pencil"></i>
      </a>
      <!-- TODO: remove aria-hidden -->
      <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg action-buttons-item" title="Supprimer l'association" (click)="removeModule()"> </i>
    </div>
  </div>
</div>
