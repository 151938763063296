import { Component, OnInit } from "@angular/core";
import { UploadFilesService } from "app/Services/Upload-Service/upload-files.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-file-upload-overlay",
  templateUrl: "./file-upload-overlay.component.html",
  styleUrl: "./file-upload-overlay.component.css",
})
export class FileUploadOverlayComponent implements OnInit {
  uploadCardFolded = false;
  uploadedFiles: File[] = [];
  progressArray: number[] = [];
  uploadInProgress = false;
  visiblePopup = true;

  progressArraySum: number = 0;

  constructor(
    private uploadFilesService: UploadFilesService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.uploadFilesService.uploadProgressArray$.subscribe((uploadProgressArray) => {
      this.progressArray = uploadProgressArray;
    });

    this.uploadFilesService.filesToUpload$.subscribe((filesToUpload) => {
      this.uploadedFiles = filesToUpload;
    });

    this.uploadFilesService.uploadInProgress$.subscribe((uploadInProgress) => {
      this.uploadInProgress = uploadInProgress;
    });

    this.uploadFilesService.uploadFinished$.subscribe((uploadFinished) => {
      if (uploadFinished) {
        this.messageService.add({ severity: "success", summary: "Upload terminé", detail: "L'upload des fichiers est terminé." });
      }
    });

    this.uploadFilesService.visiblePopup$.subscribe((visiblePopup) => {
      this.visiblePopup = visiblePopup;
    });
  }

  sumProgressArray() {
    const sumProgressArray = this.progressArray.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return Math.round(sumProgressArray / this.progressArray.length);
  }

  cancelAllUploads() {
    this.uploadedFiles.forEach((file) => {
      this.cancelUpload(file);
    });
  }

  cancelUpload(file: File) {
    const fileIndex = this.uploadedFiles.indexOf(file);
    this.uploadFilesService.cancelUpload(fileIndex);
    this.uploadedFiles.splice(fileIndex, 1);
  }
}
