export class Project {
  id!: number;
  identificationName!: string;
  isModuleUsedInProject: boolean = false;
  buildPlatforms: BuildPlatform[] = [];

  constructor(id: number, identificationName: string, buildPlatforms: BuildPlatform[]) {
    this.id = id;
    this.identificationName = identificationName;
    this.buildPlatforms = buildPlatforms;
  }
}

export class BuildPlatform {
  id!: number;
  name!: string;
}
