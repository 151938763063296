import { Component, Input } from "@angular/core";
import { Language } from "app/Models/language";

@Component({
  selector: "app-language-state",
  templateUrl: "./language-state.component.html",
  styleUrl: "./language-state.component.css",
})
export class LanguageStateComponent {
  @Input() language!: Language;
  @Input() languageState!: boolean;
  @Input() isSmall: boolean = false;
}
