import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { inputLength } from "app/ConfigVariables/input-length";
import { PointOfInterestCoordinates } from "app/Models/pointOfInterestCoordinates";
import { PointOfInterestDetail } from "app/Models/PointOfInterestDetail";
import { DataService } from "app/Services/Data-Service/data.service";

@Component({
  selector: "app-point-of-interest-configuration",
  templateUrl: "./point-of-interest-configuration.component.html",
  styleUrls: ["./point-of-interest-configuration.component.css"],
})
export class PointOfInterestConfigurationComponent implements OnInit, OnChanges {
  @Input() configurationForm: FormGroup;
  @Input() pointOfInterest?: PointOfInterestDetail;
  @Input() isCreation: boolean = false;

  @Output() changeCoordinatesDetail: EventEmitter<PointOfInterestDetail> = new EventEmitter<PointOfInterestDetail>();

  showMap: boolean = false;

  pointsOfInterestCoordinates: PointOfInterestCoordinates[] = [];

  formLatitude?: number;
  formLongitude?: number;

  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  // decimalRegex = /\d+(?:[\.,]\d+)?/;

  inputLength = inputLength;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
  ) {
    this.configurationForm = this.fb.group({
      pointOfInterestLabel: ["", Validators.required],
      languages: [""],
      latitude: ["", [Validators.min(-90), Validators.max(90)]],
      longitude: ["", [Validators.min(-180), Validators.max(180)]],
      isLocated: [false],
    });
  }

  ngOnInit() {
    const storedState = localStorage.getItem("showMapState");
    if (storedState && !this.isCreation) {
      this.showMap = JSON.parse(storedState);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["pointOfInterest"]) {
      let itineraryId = history.state.itineraryId;
      if (!itineraryId) {
        itineraryId = this.pointOfInterest?.itineraryId;
      }
      if (itineraryId) {
        this.dataService.getPointsOfInterestCoordinates(itineraryId).subscribe({
          next: (pointsOfInterestCoordinates) => {
            this.pointsOfInterestCoordinates = pointsOfInterestCoordinates;
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      }
    }
  }

  isLocated(): boolean {
    if (this.configurationForm.get(["isLocated"])?.value == true) return true;
    else return false;
  }

  countLabelCharacters(): string {
    if (this.configurationForm.get(["pointOfInterestLabel"])?.value) {
      return this.configurationForm.get(["pointOfInterestLabel"])?.value.length;
    } else {
      return "0";
    }
  }

  changeFormCoordinates(event: { latitude: number; longitude: number }) {
    this.configurationForm.patchValue({ latitude: event.latitude });
    this.configurationForm.patchValue({ longitude: event.longitude });
  }

  extendMap(state: boolean) {
    this.showMap = state;
    localStorage.setItem("showMapState", JSON.stringify(this.showMap));
  }

  onLatitudeChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const latitude = parseFloat(inputElement.value);
    if (this.pointOfInterest) {
      this.pointOfInterest = { ...this.pointOfInterest, latitude: latitude };
    } else {
      this.formLatitude = latitude;
    }
  }

  onLongitudeChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const longitude = parseFloat(inputElement.value);
    if (this.pointOfInterest) {
      this.pointOfInterest = { ...this.pointOfInterest, longitude: longitude };
    } else {
      this.formLongitude = longitude;
    }
  }
}
